
























































































































































































































import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import HelloWorld from '@/components/HelloWorld.vue';
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import Icon from '../../ui/Icon.vue';
import Navigations from '../../ui/Navigations.vue';
import { useClassificationStore } from '@/modules/classification/services/store';
import IndustryItem from '@/modules/industry/ui/IndustryItem.vue';
import { useIndustryStore } from '@/modules/industry/services/store';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import { useLoadProductPhoto } from '../../../product/helpers/loadProductPhoto';
import useLoadIndustryPhoto from '@/modules/industry/helpers/loadIndustryPhoto';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import ProductItem from '@/modules/product/ui/ProductItem.vue';
import { useNumberToCurrency } from '@/@core/helpers/formatter';
import ProductDetail from '@/modules/product/ui/ProductDetail.vue';
import { IndustryCommonForm } from '@/modules/industry/types';
import { toWhatsAppNumber } from '../../helpers/to-whatsapp-number';

type Industry = IndustryCommonForm & { products: any[] };

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    HelloWorld,
    KCard,
    Icon,
    Navigations,
    IndustryItem,
    KDialog,
    ProductItem,
    ProductDetail
  },
  name: 'LandingIndustryPage',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const classificationStore = useClassificationStore();
    const industryStore = useIndustryStore();
    const industry: Ref<Industry | null> = computed(
      () => industryStore.industry
    );
    const hasContacts = computed(
      () => industry.value?.contacts || {}
    );

    const showDetailDialog = ref(false);
    const selectedProduct = ref(null);

    const selectProduct = (product: any) => {
      selectedProduct.value = { ...product };
      showDetailDialog.value = true;
    };

    const init = async () => {
      await industryStore.getOneIndustry(props.id);
    };

    const sortBy = ref('');

    const inChargeName = (industry: IndustryCommonForm) => {
      let name = industry.ownerName;
      const hasInChargeName = !!industry.inChargeName;

      if (hasInChargeName) name = industry.inChargeName;

      return name;
    };

    onMounted(async () => {
      await init();
    });

    return {
      classificationStore,
      sortBy,
      industryStore,
      showDetailDialog,
      selectProduct,
      useLoadProductPhoto,
      selectedProduct,
      useLoadIndustryPhoto,
      isMobile,
      useNumberToCurrency,
      inChargeName,
      industry,
      hasContacts,
      toWhatsAppNumber
    };
  }
});
